import React, { Component } from 'react';
import './subscription.scss';

class Subscription extends Component {
    render() {
        return(
            <div className={`subscription-container`}>
                { /* <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
                <script>
                hbspt.forms.create({
                    region: "na1",
                    portalId: "23357155",
                    formId: "94bfabb7-fec6-4cd2-affe-c2c1a407c7f0"
                });
            </script> */ }
            </div>
        )
    }
}

export default Subscription;